<script setup lang='ts'>
import { SvgIcon } from '@/components/common'

const route = useRoute()

const activeRoutePath = computed(() => {
    return route.path
})

const menu = [
    {
        path: "/new/chat",
        icon: "ri:chat-smile-2-line",
        label: "AI对话"
    },
    {
        path: "/new/paint",
        icon: "ri:shapes-line",
        label: "图像生成"
    },
    {
        path: "/new/expert",
        icon: "ri:discuss-line",
        label: "专家问答"
    }
]

function isActive(item: any) {
    const { path } = item

    if (path)
        return path === activeRoutePath.value
}

const activeStyle = computed(() => {
    const index = menu.findIndex((item) => item.path === activeRoutePath.value)
    return {
        transform: `translateX(${index * 118}px)`
    }
})
</script>
    
<template>
    <div class="mx-auto relative flex items-center rounded-full bg-white p-1 shadow-lg">
        <div class="absolute z-0">
            <div class="tab active-bg" :style="activeStyle"></div>
        </div>

        <RouterLink v-for="item in menu" key="item.label" :to="item.path" class="tab" :class="isActive(item) ? 'active-text' : ''">
            <SvgIcon class="text-[20px]" :icon="item.icon" />
            <div class="ml-[8px]">{{ item.label }}</div>
        </RouterLink>
    </div>
</template>

<style scoped lang="scss">
.tab {
    width: 118px;
    height: 56px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    z-index: 10;
    transition: transform 0.3s cubic-bezier(.4, 0, .2, 1);

    &.active-bg {
        background-color: rgba(0, 0, 0, 0.7);
    }

    &.active-text {
        color: white;
    }
}
</style>
